window._ = require('lodash');
$ = require('jquery');
window.$ = window.jQuery = $;

(function ($) {
    ("use strict");

    $(document).ready(function(){
        window.searchProducts = function () {
            var category = document.getElementById("category");
            var searchProduct = document.getElementById("searchProduct");
            var type = document.getElementById("type");
            location.href = "/products/?searchProduct="+searchProduct.value+'&category='+category.value+'&type='+type.value;
        }
    });

})(jQuery);
